<template>
  <b-modal id="notes-trace-modal" size="lg" title="Notas" @show="handleShow">
    <div>
      <b-form @submit.stop.prevent="submit" ref="form">
        <input type="hidden" name="process_trace_id" v-model="clientId" />
        <input type="hidden" name="user_id" v-model="formFields.user_id" />
        <div class="flex space-x-4">
          <b-form-group label="Nota" class="flex-1 required">
            <b-form-textarea
              name="description"
              v-model.trim="$v.formFields.description.$model"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="text-center">
          <b-button size="sm" variant="success" @click="submit">
            <i class="glyphicon glyphicon-plus transform -translate-y-1.5"></i>
            Agregar
          </b-button>
        </div>
      </b-form>
      <v-client-table
        :data="records"
        :columns="columns"
        :options="options"
        ref="table"
        class="mt-4"
      ></v-client-table>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="outline-inverse" @click="closeModal">
        Cancelar
      </b-button>
    </template>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { notesActions } from "@/mixins/actions.js";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["row"],

  inject: [],

  mixins: [notesActions],

  computed: {
    ...mapState("user", ["user"])
  },

  data() {
    return {
      records: [],
      isLoading: false,
      formFields: {
        user_id: null,
        description: ""
      },
      columns: ["description", "user.name", "creation_date"],
      clientId: null,
      options: {
        headings: {
          description: "Nota",
          ["user.name"]: "Usuario",
          creation_date: "Fecha"
        },
        sortable: ["creation_date"],
        filterable: false
      },
      selectedRow: null
    };
  },

  validations: {
    formFields: {
      description: {
        required
      }
    }
  },

  created() {
    const me = this;
    me.formFields.user_id = me.user.id;
  }
};
</script>

<style lang="scss" scoped></style>
