<template>
  <div>
    <Widget class="shadow-xl">
      <h1 class="page-title">
        Mis Procesos
      </h1>
      <div class="flex justify-content-end pr-4">
        <b-button variant="success" @click="setSelectedRow(null)">
          <i class="glyphicon glyphicon-plus transform -translate-y-1.5"></i>
          Nuevo
        </b-button>
      </div>
      <v-client-table
        ref="table"
        :data="records"
        :columns="columns"
        :options="localOptions"
      >
        <div
          slot="actions"
          slot-scope="{ row }"
          class="flex items-center space-x-2"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            @click="setSelectedRow(row)"
          >
            <i class="glyphicon glyphicon-pencil transform -translate-y-1"></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            title="Detalles"
            v-b-modal.modal-record-details
            @click="selectedRow = row"
          >
            <i
              class="glyphicon glyphicon-eye-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            title="Documentos"
            @click="selectedRow = row"
            v-b-modal.modal-documents-form
          >
            <i
              class="glyphicon glyphicon-folder-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="handleTraceprocesslawyer(row)"
          >
            <i class="fas fa-layer-group"></i>
          </b-button>
        </div>
      </v-client-table>
    </Widget>
    <base-modal-documents-form title="Documentos" :row="selectedRow">
      <template v-slot:formfields>
        <b-form-group label="Nombre" class="flex-1">
          <b-form-input name="name"> </b-form-input>
        </b-form-group>
        <b-form-group label="Archivo" class="flex-1">
          <b-form-file
            name="file"
            ref="file"
            accept=".jpg, .png, .gif, .pdf"
          ></b-form-file>
        </b-form-group>
      </template>
    </base-modal-documents-form>
    <base-record-details :row="selectedRow">
      <template v-slot:recorddetails="slotProps">
        <div class="flex flex-col space-y-2">
          <div class="flex space-x-4">
            <div class="flex-1" v-if="slotProps.process_type">
              <span class="font-semibold">Tipo de proceso:</span>
              {{ slotProps.process_type.name }}
            </div>
            <div class="flex-1" v-if="slotProps.court">
              <span class="font-semibold">Juzgado:</span>
              {{ slotProps.court.name }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1" v-if="slotProps.judge">
              <span class="font-semibold">Juez:</span>
              {{ slotProps.judge.name }}
            </div>
            <div class="flex-1" v-if="slotProps.lawyer">
              <span class="font-semibold">Abogado:</span>
              {{ slotProps.lawyer.full_name }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1" v-if="slotProps.client">
              <span class="font-semibold">Cliente:</span>
              {{ slotProps.client.full_name }}
            </div>
            <div class="flex-1">
              <span class="font-semibold">Valor del proceso:</span>
              ${{ slotProps.process_value | numericFormat }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Radicado:</span>
              {{ slotProps.settled }}
            </div>
            <div class="flex-1">
              <span class="font-semibold">Fecha de inicio:</span>
              {{ slotProps.start_date }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1" v-if="slotProps.concept">
              <span class="font-semibold">Último estado:</span>
              {{ slotProps.concept.concept }}
            </div>
            <div class="flex-1">
              <span class="font-semibold">Prenda de garantía:</span>
              {{ slotProps.warranty_garment }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Corte de título de entrega:</span>
              {{ slotProps.delivery_title_court }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Observaciones:</span>
              {{ slotProps.notes_observations }}
            </div>
          </div>
        </div>
      </template>
    </base-record-details>
    <process-lawyer-form
      :handlePostSubmit="handlePostSubmit"
      :record="selectedRow"
      :processlawyerId="lawyer_id"
      :isDisabled="isDisabled"
      :idModal="idModal"
    />
  </div>
</template>
<script>
import { customComponentActions } from "@/mixins/actions.js";
import ProcessLawyerForm from "./ProcessLawyerForm";
import BaseModalDocumentsForm from "@/components/Base/BaseModalDocumentsForm";
import BaseRecordDetails from "@/components/Base/BaseRecordDetails";
import { mapState } from "vuex";
import { deserialize } from "jsonapi-fractal";

export default {
  inject: ["processLawyerRepository"],

  mixins: [customComponentActions],

  components: {
    ProcessLawyerForm,
    BaseModalDocumentsForm,
    BaseRecordDetails
  },

  computed: {
    ...mapState("user", ["user"]),
    columns() {
      let cols = this.localOptions.columns.map(col => {
        return col.prop;
      });

      cols.push("actions");
      return cols;
    },

    headings() {
      let heads = {};

      this.localOptions.columns.forEach(col => {
        heads[col.prop] = col.header;
      });

      heads.actions = "";
      return heads;
    }
  },

  data() {
    const me = this;
    return {
      records: [],
      localOptions: {
        columns: [
          {
            header: "Radicado",
            prop: "settled"
          },
          {
            header: "Juzgado",
            prop: "court.name"
          },
          {
            header: "Juez",
            prop: "judge.name"
          },
          {
            header: "Cliente",
            prop: "client.full_name"
          },
          {
            header: "Personal",
            prop: "is_personal"
          },
          {
            header: "Estado",
            prop: "concept.concept"
          }
        ],
        repository: {
          name: "processLawyerRepository"
        },
        primary: "id",
        sortable: [
          "court.name",
          "judge.name",
          "client.full_name",
          "concept.concept"
        ]
      },

      selectedRow: null,
      lawyer_id: null,
      isDisabled: false,
      idModal: "modal-form"
    };
  },

  methods: {
    handlePostSubmit() {
      this.handleProcessLawyer();
    },

    async handleProcessLawyer() {
      const me = this;

      let processLawyer = await me.processLawyerRepository.get(me.lawyer_id);

      me.records = deserialize(processLawyer.data);
    },

    handleTraceprocesslawyer(row) {
      this.$router.push({
        name: "Tracesprocesslawyers",
        params: {
          processlawyerId: row.id
        }
      });
    },

    setSelectedRow(row) {
      const me = this;

      row !== null && row.is_personal == false
        ? (me.isDisabled = true)
        : (me.isDisabled = false);

      me.selectedRow = row;
      me.modalProcessLawyerForm();
    },

    modalProcessLawyerForm() {
      const me = this;
      me.$bvModal.show(me.idModal);
    }
  },

  created() {
    const me = this;

    me.localOptions = {
      ...me.localOptions,
      ...{
        headings: me.headings,
        templates: {
          is_personal(h, row) {
            let isPersonal = "";

            row.is_personal == true ? (isPersonal = "Si") : (isPersonal = "No");

            return isPersonal;
          }
        }
      }
    };

    if (me.user.lawyer !== null) {
      me.lawyer_id = me.user.lawyer.id;
    }

    me.handleProcessLawyer();
  }
};
</script>
