<template>
  <div>
    <h1 class="page-title">
      Traza de Procesos de Abogados
    </h1>
    <Widget class="shadow-xl">
      <div class="flex justify-between mb-6">
        <div class="flex space-x-2">
          <div>
            <strong>Radicado:</strong>
            {{ processLawyer.settled }}
          </div>
          <div v-if="processLawyer.concept">
            <strong>Estado:</strong>
            {{ processLawyer.concept.concept }}
          </div>
          <div v-if="processLawyer.lawyer">
            <strong>Abogado:</strong>
            {{ processLawyer.lawyer.full_name }}
          </div>
        </div>
        <b-button variant="success" v-b-modal.modal-form>
          <i class="glyphicon glyphicon-plus transform -translate-y-1.5"></i>
          Nuevo
        </b-button>
      </div>
      <v-client-table
        ref="table"
        :data="records"
        :columns="columns"
        :options="localOptions"
      >
        <div
          slot="actions"
          slot-scope="{ row }"
          class="flex items-center space-x-2"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            title="Detalles"
            v-b-modal.modal-record-details
            @click="selectedRow = row"
          >
            <i
              class="glyphicon glyphicon-eye-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            title="Documentos"
            @click="selectedRow = row"
            v-b-modal.modal-documents-form
          >
            <i
              class="glyphicon glyphicon-folder-open transform -translate-y-1"
            ></i>
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            @click="selectedRow = row"
            v-b-modal.notes-trace-modal
          >
            <i class="fas fa-comments"></i>
          </b-button>
        </div>
      </v-client-table>
    </Widget>
    <base-modal-documents-form title="Documentos" :row="selectedRow">
      <template v-slot:formfields>
        <b-form-group label="Nombre" class="flex-1">
          <b-form-input name="name"> </b-form-input>
        </b-form-group>
        <b-form-group label="Archivo" class="flex-1">
          <b-form-file
            name="file"
            ref="file"
            accept=".jpg, .png, .gif, .pdf"
          ></b-form-file>
        </b-form-group>
      </template>
    </base-modal-documents-form>
    <base-record-details :row="selectedRow">
      <template v-slot:recorddetails="slotProps">
        <div class="flex flex-col space-y-2">
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Tipo de traza:</span>
              {{ slotProps.type_trace }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Estado:</span> {{ slotProps.state }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Fecha final:</span>
              {{ slotProps.end_date }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Descripción:</span>
              {{ slotProps.description }}
            </div>
          </div>
        </div>
      </template>
    </base-record-details>
    <trace-process-form
      :handlePostSubmit="handlePostSubmit"
      :record="selectedRow"
      :processlawyerId="processlawyerId"
    />
    <notes-trace-process :row="selectedRow" />
  </div>
</template>
<script>
import BaseModalDocumentsForm from "@/components/Base/BaseModalDocumentsForm";
import BaseRecordDetails from "@/components/Base/BaseRecordDetails";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";
import TraceProcessForm from "./TraceProcessForm";
import NotesTraceProcess from "./NotesTraceProcess";

export default {
  inject: ["traceprocessLawyersRepository"],

  mixins: [customComponentActions],

  computed: {
    columns() {
      let cols = this.localOptions.columns.map(col => {
        return col.prop;
      });

      cols.push("actions");
      return cols;
    },

    headings() {
      let heads = {};

      this.localOptions.columns.forEach(col => {
        heads[col.prop] = col.header;
      });

      heads.actions = "";
      return heads;
    }
  },

  components: {
    TraceProcessForm,
    NotesTraceProcess,
    BaseModalDocumentsForm,
    BaseRecordDetails
  },

  data() {
    const me = this;
    return {
      records: [],
      localOptions: {
        columns: [
          {
            header: "Tipo de traza",
            prop: "type_trace"
          },
          {
            header: "Fecha final",
            prop: "end_date"
          },
          {
            header: "Estado",
            prop: "state"
          }
        ],
        repository: {
          name: "traceprocessLawyersRepository"
        },
        primary: "id",
        sortable: ["state", "end_date"]
      },

      selectedRow: null,
      processlawyerId: me.$route.params.processlawyerId,
      processLawyer: []
    };
  },

  methods: {
    handlePostSubmit() {
      this.handleTraceProcess();
    },

    async handleTraceProcess() {
      const me = this;

      let traceProcess = await me.traceprocessLawyersRepository.get(
        me.processlawyerId
      );

      me.records = deserialize(traceProcess.data);
    },

    async handleProcessLawyer() {
      const me = this;

      let processLawyer = await me.traceprocessLawyersRepository.find(
        me.processlawyerId
      );

      me.processLawyer = deserialize(processLawyer.data);
      console.log(me.processLawyer)
    }
  },

  mounted() {
    const me = this;

    me.localOptions = {
      ...me.localOptions,
      ...{
        headings: me.headings
      }
    };

    me.handleTraceProcess();
    me.handleProcessLawyer();
  }
};
</script>
