<template>
  <base-modal-form
    title="Crear"
    ref="modalform"
    @postSubmit="handlePostSubmit"
    @show="handleShow"
  >
    <template v-slot:formfields>
      <input type="hidden" name="id" v-model="formFields.id" />
      <input
        type="hidden"
        name="process_lawyer_id"
        v-model="formFields.process_lawyer_id"
      />
      <div class="flex space-x-4">
        <b-form-group
          label="Tipo de traza"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.type_trace)"
        >
          <input
            type="hidden"
            name="type_trace"
            v-model="$v.formFields.type_trace.$model"
          />
          <v-select
            :options="typeTraces"
            :reduce="typeTrace => typeTrace.value"
            label="text"
            v-model.trim="$v.formFields.type_trace.$model"
            :state="validateState('type_trace')"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Estado"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.state)"
        >
          <b-form-radio-group
            name="state"
            :options="states"
            v-model.trim="$v.formFields.state.$model"
            :state="validateState('state')"
          ></b-form-radio-group>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Fecha final"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.end_date)"
        >
          <b-form-input
            type="date"
            name="end_date"
            v-model.trim="$v.formFields.end_date.$model"
            :state="validateState('end_date')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Descripción"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.description)"
        >
          <b-form-textarea
            name="description"
            v-model.trim="$v.formFields.description.$model"
            :state="validateState('description')"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </template>
  </base-modal-form>
</template>

<script>
import BaseModalForm from "@/components/Base/BaseModalForm";
import { required } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";

export default {
  props: ["handlePostSubmit", "record", "processlawyerId"],
  mixins: [customComponentActions],
  inject: ["traceprocessLawyersRepository", "typeTracesProcessRepository"],

  components: {
    BaseModalForm
  },

  data() {
    return {
      formFields: {
        id: null,
        state: "",
        description: "",
        end_date: "",
        process_lawyer_id: this.processlawyerId,
        type_trace: ""
      },

      repository: "traceprocessLawyersRepository",
      primary: "id",
      states: [
        {
          value: "ACTIVE",
          text: "Activo"
        },
        {
          value: "INACTIVE",
          text: "Inactivo"
        }
      ],
      typeTraces: []
    };
  },

  watch: {
    processlawyerId(newprocesslawyerId) {
      this.formFields.process_lawyer_id = newprocesslawyerId;
    }
  },

  validations: {
    formFields: {
      state: {
        required
      },
      description: {
        required
      },
      end_date: {
        required
      },
      type_trace: {
        required
      }
    }
  },

  methods: {
    handleShow() {
      const me = this;

      if (me.record) {
        me.formFields = { ...me.record };
      }
    }
  },

  async mounted() {
    const me = this;

    let typeTraces = await me.typeTracesProcessRepository.get();

    me.typeTraces = [
      {
        value: "",
        text: "Seleccione tipo"
      },
      ...deserialize(typeTraces.data).map(typeTrace => {
        return {
          value: typeTrace.concept,
          text: typeTrace.concept
        };
      })
    ];
  }
};
</script>

<style lang="scss" scoped></style>
